import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RejectSearchKey} from '../../shared/reject-search-keys';
import {IValidationProfileError} from '../../shared/validation-profile-error';

interface ValidationProfileErrorsReducerState {
  errors: Record<RejectSearchKey, IValidationProfileError>;
}

const initialState: ValidationProfileErrorsReducerState = {
  errors: {} as Record<RejectSearchKey, IValidationProfileError>,
};

const validationProfileErrorsSlice = createSlice({
  name: 'validationProfileErrors',
  initialState,
  reducers: {
    setValidationErrors: (state, action: PayloadAction<IValidationProfileError[]>) => {
      action.payload.forEach((validationErr) => {
        state.errors[validationErr.field] = validationErr;
      });
    },
    deleteValidationError: (state, action: PayloadAction<RejectSearchKey>) => {
      delete state.errors[action.payload];
    },
  },
});

export const {setValidationErrors, deleteValidationError} = validationProfileErrorsSlice.actions;
export const validationProfileErrors = validationProfileErrorsSlice.reducer;
