import axios from 'axios';
import TrafficAnalyticsService from '../analytics-service/traffic-analytics-service';
import {sleep} from '../../util/helpers/sleep';

class PostbackService {
  private readonly trafficAnalyticsService: TrafficAnalyticsService;

  constructor(trafficAnalyticsService: TrafficAnalyticsService) {
    this.trafficAnalyticsService = trafficAnalyticsService;
  }

  async init() {
    const pixel = this.trafficAnalyticsService.getUTMParams()?.utm?.pixel ?? '';
    if (!pixel) {
      // If no pixel or invalid do nothing
      return;
    }

    await this.waitForFbq();

    // const isInitialized = this.isPixelInitialized(pixel);
    // if (isInitialized) {
      // Pixel already initialized
      // return;
    // }
    window.fbq('init', pixel);
    window.fbq('track', 'ViewContent');
  }

  async sendEvent() {
    try {
      const utmParams = this.trafficAnalyticsService.getUTMParams();
      if (!utmParams) return;

      const sub2 = utmParams.utm.sub2;

      if (window.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }

      if (sub2) {
        await axios.get(`https://avtodolph.space/fe9fdb5/postback?subid=${sub2}&status=lead&payout=REPLACE`);
      }
    } catch (e) {
      console.error('Error sending postback', e);
    }
  }

  private isPixelInitialized(pixelId: string): boolean {
    return Boolean(window?.fbq?.getState()?.pixels?.some((pixel: {id: string}) => pixel.id === pixelId));
  }

  private async waitForFbq(timeout: number = 10000): Promise<void> {
    const startTime = Date.now();
    const delayMS = 100;

    while (Date.now() - startTime < timeout) {
      if (window.fbq) {
        console.log('Facebook Pixel initialized');
        return;
      }
      console.log('Waiting for Facebook Pixel to initialize...');
      await sleep(delayMS);
    }

    throw new Error('Facebook Pixel initialization timeout');
  }
}

export default PostbackService;
