import {ILock, ILockManager, LockFactory} from './i-lock';

class LockManager implements ILockManager {
  private readonly lockFactory: LockFactory;
  private locks: Map<string, ILock> = new Map();

  constructor(lockFactory: LockFactory) {
    this.lockFactory = lockFactory;
  }

  public getOrCreateLock(name: string): ILock {
    if (!this.locks.has(name)) {
      // If lock does not exist then create it
      this.locks.set(name, this.lockFactory());
    }
    return this.locks.get(name)!;
  }
}

export default LockManager;
