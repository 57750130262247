import {OnUpdateLanguageData} from '../../shared/socket/client-to-server-events/events';
import {UPDATE_LANGUAGE} from '../../shared/socket/socket-events';
import {getSocketWithTimeout} from '../get-socket';

export const emitUpdateLanguage = async (data: OnUpdateLanguageData): Promise<void> => {
  const socket = await getSocketWithTimeout();
  return new Promise((resolve, reject) => {
    socket.emit(UPDATE_LANGUAGE, data, (timeoutErr, err, response) => {
      if (err || timeoutErr) {
        return reject(err || timeoutErr);
      }
      resolve();
    });
  });
};
