import React from 'react';
import {useI18n} from '../../../../hooks/use-i18n';

const ExternalBrowserLoginBubble: React.FC = () => {
  const {t} = useI18n();

  return (
    <div className="fixed right-20 z-50 flex flex-col items-start p-20 gap-3 w-[205px] bg-white shadow-lg rounded-[24px_8px_24px_24px]">
      <p className="text-15 leading-22 font-medium text-black">{t('to-login-open-external-browser')}</p>
    </div>
  );
};

export default ExternalBrowserLoginBubble;
