export function formatAmountCents(amountCents: number, {forceShowCents = true} = {}) {
  const amountDollars = amountCents / 100;
  if (!forceShowCents && amountCents % 100 === 0) {
    // If we don't need to show cents, and the amount is an even dollar amount,
    // then don't show cents.
    return `$${amountDollars.toFixed(0)}`;
  }
  const formattedAmount = amountDollars.toFixed(2);

  return `$${formattedAmount}`;
}
