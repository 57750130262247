import {useEffect, useState} from 'react';
import debounce from 'lodash.debounce';
import {MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH} from '../../../shared/is-valid-password';

enum PasswordInvalidReason {
  PasswordsDontMatch,
  PasswordLengthError,
  PasswordHasNoDigit,
}

const mapInvalidReasonToError: Record<PasswordInvalidReason, string> = {
  [PasswordInvalidReason.PasswordLengthError]: 'password-length-error',
  [PasswordInvalidReason.PasswordHasNoDigit]: 'password-no-digit-error',
  [PasswordInvalidReason.PasswordsDontMatch]: 'password-do-not-match-error',
};

export const usePassword = (): {
  password: string;
  confirmPassword: string;
  setPassword: (val: string) => void;
  setConfirmPassword: (val: string) => void;
  invalidReasonKey: string | null;
  passwordErrored: boolean;
  confirmPasswordErrored: boolean;
} => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordErrored, setPasswordErrored] = useState(false);
  const [invalidReasonKey, setInvalidReasonKey] = useState<string | null>(null);
  const [confirmPasswordErrored, setConfirmPasswordErrored] = useState(false);
  const [passwordActiveErrors, setPasswordActiveErrors] = useState<PasswordInvalidReason[]>([]);

  useEffect(() => {
    const debouncedSetActiveErrors = debounce(
      (errors) => {
        setPasswordActiveErrors(errors);
      },
      500,
      {leading: false},
    );
    if (!password && !confirmPassword) {
      // Reset errors if passwords are empty
      setPasswordActiveErrors([]);
      return;
    }

    const hasDigit = /\d/.test(password);
    const activeErrors: PasswordInvalidReason[] = [];
    if (password.length < MIN_PASSWORD_LENGTH || password.length > MAX_PASSWORD_LENGTH) {
      // Password must be longer than min length
      activeErrors.push(PasswordInvalidReason.PasswordLengthError);
    }
    if (!hasDigit) {
      // Password must have at least one digit
      activeErrors.push(PasswordInvalidReason.PasswordHasNoDigit);
    }
    if (password !== confirmPassword) {
      // Passwords must match
      activeErrors.push(PasswordInvalidReason.PasswordsDontMatch);
    }

    // If user has set password correctly then update it right away otherwise debounce execution
    if (activeErrors.length === 0) {
      setPasswordActiveErrors(activeErrors);
    } else {
      debouncedSetActiveErrors(activeErrors);
    }
    return () => {
      debouncedSetActiveErrors.cancel();
    };
  }, [password, confirmPassword]);

  useEffect(() => {
    if (!passwordActiveErrors.length) {
      // Reset error states if invalid reason is null
      setPasswordErrored(false);
      setConfirmPasswordErrored(false);
    } else {
      // password should be in error state if there are any active errors but not only PasswordsDontMatch
      setPasswordErrored(
        passwordActiveErrors.length !== 0 &&
          passwordActiveErrors.some((err) => err !== PasswordInvalidReason.PasswordsDontMatch),
      );
      // confirmPassword should be in error state only if passwords don't match and this is the only error
      setConfirmPasswordErrored(
        passwordActiveErrors.length === 1 && passwordActiveErrors.includes(PasswordInvalidReason.PasswordsDontMatch),
      );
    }
  }, [passwordActiveErrors]);

  useEffect(() => {
    if (passwordActiveErrors.length === 0) {
      // If there are no errors then set reason to null
      setInvalidReasonKey(null);
    } else if (passwordActiveErrors.length === 1) {
      // If there is only one reason then show specific error
      setInvalidReasonKey(mapInvalidReasonToError[passwordActiveErrors[0]]);
    } else {
      // Filter errors related to password (not confirmPassword)
      const passwordErrors = passwordActiveErrors.filter(
        (reason) => reason !== PasswordInvalidReason.PasswordsDontMatch,
      );
      // If there is ony one password error then show specific error
      if (passwordErrors.length === 1) {
        setInvalidReasonKey(mapInvalidReasonToError[passwordActiveErrors[0]]);
      } else {
        // If there are more than one error then show general error message
        setInvalidReasonKey('password-error');
      }
    }
  }, [passwordActiveErrors]);

  return {
    password,
    confirmPassword,
    setConfirmPassword,
    setPassword,
    passwordErrored,
    confirmPasswordErrored,
    invalidReasonKey,
  };
};
