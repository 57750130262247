import {ReactComponent as IconCloseFill} from '../assets/svg/close-fill.svg';
import {PropsWithChildren} from 'react';
import {twMerge} from 'tailwind-merge';
import classNames from 'classnames';

interface Props {
  isVisible: boolean;
  className?: string;
  hideIcon?: boolean;
}

export const ErrorMessage = (props: PropsWithChildren<Props>) => {
  const {isVisible, children, className, hideIcon = false} = props;
  return (
    <div
      className={classNames(twMerge('flex items-center gap-16 font-bold transition-opacity', className), {
        'opacity-100 delay-100 duration-300': isVisible,
        'opacity-0 h-0 delay-0 duration-0': !isVisible,
      })}
    >
      <IconCloseFill
        className={classNames('fill-red wh-18', {
          hidden: hideIcon,
        })}
      />
      <div className="text-red">{children}</div>
    </div>
  );
};
