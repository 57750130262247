import {sleep} from '../util/helpers/sleep';
import {SocketIOWithEvents} from '../types/socket/socket-type';

const SOCKET_TIMEOUT_MS = 5000;

export const getSocket = async (): Promise<SocketIOWithEvents> => {
  while (!global.cachedSocket) {
    await sleep(100);
  }
  return global.cachedSocket as SocketIOWithEvents;
};

export const getSocketWithTimeout = async (timeoutMS = SOCKET_TIMEOUT_MS) => {
  const socket = await getSocket();
  return socket.timeout(timeoutMS);
};
