import ActiveLink from '../ActiveLink';
import {ReactComponent as IconClose} from '../../assets/svg/close.svg';

export const CloseButton = ({hide}: {hide: () => void}) => {
  return (
    <div className="absolute inset-x-0 -top-1 flex items-center justify-between px-container lg:top-10 lg:px-30 ">
      <ActiveLink href="/" activeClassName="pointer-events-none">
        <span className="font-bold lg:hidden lg:text-[2rem]">AnonFun</span>
      </ActiveLink>
      <button
        className="btn-base -mr-20 ml-auto p-20 text-primary transition-colors hover:text-black"
        aria-label="Close"
        onClick={hide}
      >
        <IconClose className="fill-current wh-22" />
      </button>
    </div>
  );
};
