import {api} from '../../../http/api';
import {LoginPostResponse} from '../../../shared/login-response';
import {AxiosError} from 'axios';
import {isSharedError} from '../../../shared/shared-error';
import {useI18n} from '../../../hooks/use-i18n';
import {createRef, useImperativeHandle, useRef} from 'react';
import OnSuccessLogin, {OnSuccessLoginRef} from './OnSuccessLogin';

export interface DirectLoginRef {
  login: () => Promise<void>;
}

const directLoginRef = createRef<DirectLoginRef>();

export const DirectLoginHandler: DirectLoginRef = (() => {
  const login: DirectLoginRef['login'] = async (): Promise<void> => {
    if (directLoginRef.current?.login) {
      await directLoginRef.current.login();
    }
  };

  return {
    login,
  };
})();

const DirectLogin = () => {
  const {t} = useI18n();
  const successLoginRef = useRef<OnSuccessLoginRef>(null);
  const directLogin = async (): Promise<void> => {
    try {
      const response = await api.post<LoginPostResponse>('api/v1/login', {method: 'direct'}, {withCredentials: true});
      if (!response.data.success) {
        alert(response.data.error);
        return;
      }
      successLoginRef?.current?.onSuccessLogin(response.data.data);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (isSharedError(e)) {
          alert(t(e.translation));
          return;
        }
        alert(e?.response?.data?.error ?? e?.response?.statusText);
        return;
      }
      if (e instanceof Error) {
        alert(e.message);
        return;
      }
      alert(`Unexpected error: ${e}`);
    }
  };

  useImperativeHandle(directLoginRef, () => ({
    login: directLogin,
  }));

  return (
    <>
      <OnSuccessLogin ref={successLoginRef} />
    </>
  );
};

export default DirectLogin;
