import {CSSProperties, memo} from 'react';
import cls from './Skeleton.module.scss';
import {twMerge} from 'tailwind-merge';

interface SkeletonProps {
  className?: string;
  height?: string | number;
  width?: string | number;
  border?: string;
}

export const Skeleton = memo((props: SkeletonProps) => {
  const {className, height, width, border} = props;

  const styles: CSSProperties = {
    width,
    height,
    borderRadius: border,
  };

  return <div className={twMerge(cls.Skeleton, className)} style={styles} />;
});
