import {useI18n} from '../../../../hooks/use-i18n';

const ForgotPassword = ({onClick}: {onClick: () => void}) => {
  const {t} = useI18n();
  return (
    <button
      type={'button'}
      className={'btn-base link link--primary-gradient mb-24 self-end text-base'}
      onClick={onClick}
    >
      {t('forgot-password')}
    </button>
  );
};

export default ForgotPassword;
