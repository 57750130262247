import {useI18n} from '../../../hooks/use-i18n';
import classNames from 'classnames';

interface Props {
  disabled: boolean;
  titleKey: string;
}

const SubmitButton = (props: Props) => {
  const {disabled, titleKey} = props;
  const {t} = useI18n();
  return (
    <button
      type={'submit'}
      disabled={disabled}
      className={classNames('btn btn--sm btn--primary h-52', {
        disabled: disabled,
      })}
    >
      {t(titleKey)}
    </button>
  );
};

export default SubmitButton;
