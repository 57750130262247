import {combineReducers, configureStore, UnknownAction} from '@reduxjs/toolkit';
import {persistMiddleware} from './middlewares/persist-middleware';
import {reHydrateStore} from './middlewares/rehydrate-store';
import {userReducer} from './reducers/user-reducer';
import {verificationReducer} from './reducers/verification-reducer';
import {globalReducer} from './reducers/global-reducer';
import {typingStatusesReducer} from './reducers/typing-statuses-reducer';
import {globalPersistedReducer} from './reducers/global-persisted-reducer';
import {USER_LOGOUT} from './constants';
import {savedPaymentMethodsReducer} from './reducers/saved-payment-methods-reducer';
import {savedWithdrawalMethodsReducer} from './reducers/saved-withdrawal-methods-reducer';
import {modelsReducer} from './reducers/models-reducer';
import {validationProfileErrors} from './reducers/validation-profile-errors-reducer';
import {albumsReducer} from './reducers/albums-reducer';

const combinedReducer = combineReducers({
  user: userReducer,
  verification: verificationReducer,
  global: globalReducer,
  globalPersisted: globalPersistedReducer,
  typingStatuses: typingStatusesReducer,
  savedPaymentMethods: savedPaymentMethodsReducer,
  savedWithdrawalMethods: savedWithdrawalMethodsReducer,
  models: modelsReducer,
  validationProfileErrors: validationProfileErrors,
  albums: albumsReducer,
});

const rootReducer = (state: any, action: UnknownAction) => {
  if (action.type === USER_LOGOUT) {
    const globalReducerState = {...state.global};
    // When we set state to undefined it will return state to its initial value
    state = undefined;
    // Preserve global reducer state since we don't need to clear it on logout
    state = {global: globalReducerState, ...state};
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: reHydrateStore(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(persistMiddleware),
});
