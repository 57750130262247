import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {useI18n} from '../../../hooks/use-i18n';
import {capitalize} from '../../../util/helpers/capitalize';
import {useEffect, useState} from 'react';
import {isValidEmail} from '../../../shared/is-valid-email';
import debounce from 'lodash.debounce';
import {checkEmailExists} from '../../../http/check-email-exists';

interface Props {
  email: string;
  setEmail: (val: string) => void;
  setEmailIncorrect: (val: boolean) => void;
  checkEmailExistence: boolean;
}

const MIN_EMAIL_LENGTH = 3; // source: https://stackoverflow.com/questions/1423195/what-is-the-actual-minimum-length-of-an-email-address-as-defined-by-the-ietf

const EmailInput = (props: Props) => {
  const {email, setEmail, setEmailIncorrect, checkEmailExistence} = props;
  const {t, T} = useI18n();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailExists, setIsEmailExists] = useState(false);
  const [emailError, setEmailError] = useState('');
  const shouldShowError = email.length > MIN_EMAIL_LENGTH && emailError;

  useEffect(() => {
    const debouncedSetIsEmailValid = debounce(
      () => {
        setIsEmailValid(isValidEmail(email));
      },
      1000,
      {leading: false},
    );

    if (email.length < MIN_EMAIL_LENGTH) {
      // Reset valid state
      setIsEmailValid(true);
      return;
    }

    // If email is valid then set state right away otherwise run debounced function
    if (isValidEmail(email)) {
      setIsEmailValid(true);
    } else {
      debouncedSetIsEmailValid();
    }

    return () => {
      debouncedSetIsEmailValid.cancel();
    };
  }, [email]);

  useEffect(() => {
    const debouncedCheckEmailExists = debounce(
      (email) => {
        checkEmailExists(email).then(setIsEmailExists);
      },
      250,
      {leading: false},
    );
    if (checkEmailExistence && isEmailValid && email.length > MIN_EMAIL_LENGTH) {
      debouncedCheckEmailExists(email);
    }
    return () => {
      debouncedCheckEmailExists.cancel();
    };
  }, [isEmailValid, email, checkEmailExistence]);

  useEffect(() => {
    if (!isEmailValid) {
      setEmailError('invalid-email-error');
    } else if (isEmailExists) {
      setEmailError('email-is-taken-error');
    } else {
      setEmailError('');
    }
  }, [isEmailValid, isEmailExists]);

  useEffect(() => {
    setEmailIncorrect(!isEmailValid || isEmailExists || email.length < MIN_EMAIL_LENGTH);
  }, [isEmailValid, isEmailExists, email, setEmailIncorrect]);

  return (
    <label
      className={classNames('text-field-wrapper text-field-small relative flex w-full', {
        'has-value': Boolean(email),
        'mb-14': !shouldShowError,
        'mb-32': shouldShowError,
      })}
    >
      <div className="text-field-wrapper__title">{capitalize(t('email'))}</div>
      <input
        type="text"
        className={classNames('text-field text-field-small rounded-sm pr-60 font-normal', {
          'border-red': shouldShowError,
        })}
        autoComplete="email"
        value={email}
        onChange={(e) => setEmail(e.target.value ?? '')}
      />
      {shouldShowError && (
        <div
          data-testid="email-error"
          className={
            'absolute bottom-[-2rem] left-0 max-w-full animate-appearance truncate text-nano font-semibold text-red lg:bottom-[-3rem] lg:text-[1.5rem] lg:text-tiny'
          }
        >
          {emailError === 'email-is-taken-error' ? (
            <T
              keyName={emailError}
              params={{
                a: (text) => (
                  <Link to={'/login'} className={'link text-red'}>
                    {text}
                  </Link>
                ),
              }}
            />
          ) : (
            t(emailError)
          )}
        </div>
      )}
    </label>
  );
};

export default EmailInput;
