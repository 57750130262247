export class LoginTokenStorage {
  private static _cookieKey: string = 'cookie';

  static get() {
    return localStorage.getItem(this._cookieKey);
  }

  static set(value: string) {
    return localStorage.setItem(this._cookieKey, value);
  }

  static delete() {
    return localStorage.removeItem(this._cookieKey);
  }
}
