import {Main} from '../../modules/main';
import {LoginTokenStorage} from '../../storages/login-token-storage';
import {Navigate} from 'react-router-dom';

interface Props {
  showLoginModalOnMount: boolean;
}

const MainPage = ({showLoginModalOnMount}: Props) => {
  const cookie = LoginTokenStorage.get();
  if (cookie) {
    // If user has cookie then we navigate them inside app
    return <Navigate to="/app/chat/search" />;
  }
  return <Main showLoginModalOnMount={showLoginModalOnMount} />;
};

export default MainPage;
