import {useEffect} from 'react';
import {getUserCountry} from '../util/get-user-country';
import {useAppSelector} from '../redux/hooks/use-app-selector';
import {useAppDispatch} from '../redux/hooks/use-app-dispatch';
import {updateUserCountry as updateUserCountryPersisted} from '../redux/reducers/global-persisted-reducer';
import {updateUserCountry as updateUserCountrySession} from '../redux/reducers/global-reducer';

// make sure to only request user country via api once per mount
let runOnce = false;

export const useUserCountry = (): string | null => {
  const userCountry = useAppSelector((state) => state.global.userCountry ?? state.globalPersisted.userCountry);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (runOnce) {
      return;
    }
    runOnce = true;
    getUserCountry().then((country) => {
      dispatch(updateUserCountryPersisted(country));
      dispatch(updateUserCountrySession(country));
    });
  }, [dispatch]);

  return userCountry;
};
