import {useAppSelector} from './use-app-selector';
import {useEffect} from 'react';
import {useAppDispatch} from './use-app-dispatch';
import {updateIsMobile} from '../reducers/global-reducer';

export const useIsMobile = (): boolean => {
  return useAppSelector((state) => state.global.isMobile);
};

export const useHandleIsMobile = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const mobileMql = window?.matchMedia('(max-width: 1023px)');
    const checkMobile = () => {
      dispatch(updateIsMobile(mobileMql?.matches));
    };
    checkMobile();
    mobileMql?.addEventListener('change', checkMobile);
    return () => {
      mobileMql?.removeEventListener('change', checkMobile);
    };
  }, [dispatch]);
};
