import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IUserPublicInfo} from '../../shared/user';

export type ModelsState = {
  models: Models;
};

type Models = {[modelId: string]: IUserPublicInfo & {hideFromSearch?: boolean}};

const initialState: ModelsState = {models: {}};

const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    upsertModel: (state, action: PayloadAction<IUserPublicInfo>) => {
      state.models[action.payload.userId] = action.payload;
    },
    upsertModels: (state, action: PayloadAction<IUserPublicInfo[]>) => {
      action.payload.forEach((model) => (state.models[model.userId] = model));
    },
    updateModels: (state, action: PayloadAction<IUserPublicInfo[]>) => {
      state.models = action.payload.reduce<Models>((res, model) => {
        res[model.userId] = model;
        return res;
      }, {});
    },
    hideModelFromSearch: (state, action: PayloadAction<string>) => {
      if (state.models[action.payload]) {
        state.models[action.payload].hideFromSearch = true;
      }
    },
    deleteModel: (state, action: PayloadAction<string>) => {
      delete state.models[action.payload];
    },
    clearModels: (state) => {
      state.models = {};
    },
  },
});

export const {updateModels, upsertModel, deleteModel, upsertModels, clearModels, hideModelFromSearch} =
  modelsSlice.actions;
export const modelsReducer = modelsSlice.reducer;
