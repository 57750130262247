export class SharedError extends Error {
  public translation: string;
  public data: any;

  constructor(message: string, translation: string, data?: any) {
    super(message);
    this.name = 'SharedError';
    this.message = message;
    this.translation = translation;
    this.data = data;
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      stacktrace: this.stack,
      translation: this.translation,
      data: this.data,
    };
  }
}

export const isSharedError = (e: unknown): e is SharedError => (e as SharedError)?.name === 'SharedError';

export const NotEnoughBalanceError = new SharedError('insufficient_balance', 'insufficient-balance-text');
export const WithdrawalAmountTooSmallError = new SharedError(
  'withdrawal_amount_too_small',
  'withdrawal-amount-too-small-text',
);

export const WithdrawalMethodNotFoundError = new SharedError(
  'withdrawal_method_not_found',
  'withdrawal-method-not-found-text',
);

export const InvalidData = new SharedError('invalid-data', 'invalid-data-text');

export const ModelsCannotSearchWithoutVerification = new SharedError(
  'models-cannot-start-without-verification',
  'models-cannot-start-without-verification-text',
);

export const CustomersCannotJoinSearchQueue = new SharedError(
  'customers-cannot-join-search-queue',
  'customers-cannot-join-search-queue-text',
);

export const OnlyCustomersAllowed = new SharedError('only-customers-allowed', 'only-customers-allowed-text');

export const OnlyModelsAllowed = new SharedError('only-models-allowed', 'only-models-allowed-text');

export const ModelNotAvailable = new SharedError('model-not-available', 'model-not-available-text');

export const AlreadyHaveCommonChat = new SharedError('already-have-common-chat', 'already-have-common-chat-text');

export const createAlreadyHaveCommonChatSharedErr = (data: any) =>
  new SharedError('already-have-common-chat', 'already-have-common-chat-text', data);

export const AlreadyLinkedTelegram = new SharedError('already-linked-telegram', 'already-linked-telegram-text');

export const TelegramIsNotLinked = new SharedError('telegram-is-not-linked', 'telegram-is-not-linked-text');

export const UserDoesNotBelongToDialog = new SharedError(
  'user-does-not-belong-to-dialog',
  'user-does-not-belong-to-dialog-text',
);

export const DialogClosed = new SharedError('dialog-closed-error', 'dialog-closed-error-text');

export const CannotDeleteActiveDialog = new SharedError('cannot-delete-open-dialog', 'cannot-delete-open-dialog-text');

export const UnsupportedMessageType = new SharedError('unsupported-message-type', 'unsupported-message-type-text');

export const TransactionIdIsEmpty = new SharedError('transaction-id-is-empty', 'transaction-id-is-empty-text');

export const AmountCentsIsEmpty = new SharedError('amount-cents-is-empty', 'amount-cents-is-empty-text');

export const ResendConfirmationEmailTooOften = new SharedError(
  'resend-confirmation-email-too-often',
  'resend-confirmation-error-too-often',
);

export const RateLimitExceededError = new SharedError('rate-limit-exceed-error', 'rate-limit-exceed-error');

export const WrongEmailOrPasswordEasy = new SharedError('wrong-email-or-password-easy', 'wrong-email-or-password-easy');

export const WrongEmailOrPasswordHard = new SharedError('wrong-email-or-password-hard', 'wrong-email-or-password-hard');

export const ForbiddenActionInChatWithAdmin = new SharedError(
  'forbidden-action-in-chat-with-admin',
  'forbidden-action-in-chat-with-admin',
);

export const InvalidDepositAmount = new SharedError('invalid-deposit-amount', 'invalid-deposit-amount');

export const InvalidCentsAmount = new SharedError('invalid-cents-amount', 'invalid-cents-amount');

export const NotAllowedToResubmitVerification = new SharedError(
  'not-allowed-to-resubmit-verification',
  'not-allowed-to-resubmit-verification',
);

export const SuchEmailAlreadyExists = new SharedError('such-email-already-exists', 'such-email-already-exists-text');

export const NotPaidMedia = new SharedError('not-paid-media', 'not-paid-media');

export const PaidMediaAlreadyUnlocked = new SharedError('paid-media-already-unlocked', 'paid-media-already-unlocked');

export const Unauthorized = new SharedError('unauthorized', 'unauthorized');

export const CannotSelfPurchase = new SharedError('cannot-self-purchase', 'cannot-self-purchase');

export const AlbumNotAvailableForPurchase = new SharedError(
  'album-not-available-for-purchase',
  'album-not-available-for-purchase',
);
