import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IAlbumCustomer, IAlbumModel} from '../../shared/album';

export type AlbumsState = {
  albums: {[albumId: string]: IAlbumModel};
  modelAlbums: {[modelId: string]: {[albumId: string]: IAlbumCustomer}};
};

const initialState: AlbumsState = {albums: {}, modelAlbums: {}};

const albumsSlice = createSlice({
  name: 'albums',
  initialState,
  reducers: {
    upsertAlbum: (state, action: PayloadAction<IAlbumModel>) => {
      if (action.payload.albumId) {
        state.albums[action.payload.albumId] = action.payload;
      }
    },
    upsertAlbums: (state, action: PayloadAction<Array<IAlbumModel>>) => {
      action.payload.forEach((album) => {
        if (album.albumId) {
          state.albums[album.albumId] = album;
        }
      });
    },
    upsertModelAlbum: (state, action: PayloadAction<IAlbumCustomer>) => {
      if (action.payload.albumId) {
        if (!state.modelAlbums[action.payload.creatorId]) {
          state.modelAlbums[action.payload.creatorId] = {};
        }
        state.modelAlbums[action.payload.creatorId][action.payload.albumId] = action.payload;
      }
    },
    upsertModelAlbums: (state, action: PayloadAction<Array<IAlbumCustomer>>) => {
      action.payload.forEach((album) => {
        if (album.albumId) {
          if (!state.modelAlbums[album.creatorId]) {
            state.modelAlbums[album.creatorId] = {};
          }
          state.modelAlbums[album.creatorId][album.albumId] = album;
        }
      });
    },
  },
});

export const {upsertAlbum, upsertAlbums, upsertModelAlbum, upsertModelAlbums} = albumsSlice.actions;
export const albumsReducer = albumsSlice.reducer;
