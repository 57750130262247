import classNames from 'classnames';
import {capitalize} from '../../../util/helpers/capitalize';
import {useI18n} from '../../../hooks/use-i18n';
import {ReactComponent as IconEyeSlash} from '../../../assets/svg/eye-slash.svg';

interface Props {
  label: string;
  password: string;
  type?: 'new-password' | 'current-password';
  setPassword: (val: string) => void;
  hasError: boolean;
  revealed: boolean;
  toggleRevealed: () => void;
}

const PasswordInput = (props: Props) => {
  const {label, password, setPassword, hasError, revealed, toggleRevealed, type} = props;
  const {t} = useI18n();
  return (
    <label
      className={classNames('text-field-wrapper text-field-small relative mb-14 flex w-full', {
        'has-value': Boolean(password),
      })}
    >
      <div className="text-field-wrapper__title">{capitalize(t(label))}</div>
      <input
        type={revealed ? 'text' : 'password'}
        className={classNames('text-field text-field-small relative rounded-sm pr-60 font-normal', {
          'border-red': hasError,
        })}
        autoComplete={type}
        value={password}
        onChange={(e) => setPassword(e.target.value ?? '')}
      />
      <RevealPasswordButton
        className={'absolute bottom-0 right-18 top-0 h-full'}
        revealed={revealed}
        onReveal={toggleRevealed}
      />
    </label>
  );
};

interface RevealPasswordProps {
  revealed: boolean;
  onReveal: () => void;
  className?: string;
}

const RevealPasswordButton = ({revealed, onReveal, className}: RevealPasswordProps) => {
  return (
    <button
      type={'button'}
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        onReveal();
      }}
    >
      <IconEyeSlash style={{stroke: revealed ? '#8423FF' : '#A2A2A2'}} />
    </button>
  );
};

export default PasswordInput;
