import {useI18n} from '../../../../hooks/use-i18n';
import {ReactComponent as IconResetPassword} from '../../../../assets/svg/reset-password.svg';
import {FormEvent, useRef} from 'react';
import {api} from '../../../../http/api';
import {LoginPostResponse} from '../../../../shared/login-response';
import {useToggle} from '../../../../hooks/use-toggle';
import {usePassword} from '../../lib/use-password';
import PasswordInput from '../PasswordInput';
import {PasswordError} from '../PasswordError';
import classNames from 'classnames';
import OnSuccessLogin, {OnSuccessLoginRef} from '../OnSuccessLogin';
import {AxiosError} from 'axios';

interface Props {
  token: string;
}

const SetNewPassword = (props: Props) => {
  const {token} = props;
  const {t} = useI18n();
  const [revealed, toggleRevealed] = useToggle();
  const successLoginRef = useRef<OnSuccessLoginRef>(null);
  const {
    password,
    confirmPassword,
    setPassword,
    setConfirmPassword,
    invalidReasonKey,
    passwordErrored,
    confirmPasswordErrored,
  } = usePassword();

  // Disable create button if there are active errors or password is empty
  const disableSubmitButton = passwordErrored || confirmPasswordErrored || !password.length;

  const onResetPasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (disableSubmitButton) {
      return;
    }
    try {
      const result = await api.post<LoginPostResponse>('api/v1/auth/password-reset/new', {
        password,
        token,
      });
      if (result.data.success) {
        successLoginRef?.current?.onSuccessLogin(result.data.data);
        return;
      }
      alert(result.data.error);
    } catch (e) {
      if (e instanceof AxiosError) {
        alert(e?.response?.data?.error);
        return;
      }
      alert(e);
    }
  };

  return (
    <>
      <OnSuccessLogin ref={successLoginRef} />
      <div className="flex min-h-[calc(100dvh-var(--header-h))] items-center justify-center px-20 py-28 lg:px-40 lg:py-80 xl:px-110">
        <form className="flex w-full flex-col items-center lg:mx-auto lg:w-380" onSubmit={onResetPasswordSubmit}>
          <IconResetPassword />
          <div className="mb-20 mt-54 text-center text-xl font-bold leading-[1.17] lg:text-[2.8rem]">
            {t('reset-password')}
          </div>
          <div className="mb-34 text-center text-base text-black/60">{t('enter-new-password')}</div>
          <PasswordInput
            revealed={revealed}
            toggleRevealed={toggleRevealed}
            type={'new-password'}
            label={'password'}
            password={password}
            setPassword={setPassword}
            hasError={passwordErrored}
          />
          <PasswordInput
            revealed={revealed}
            toggleRevealed={toggleRevealed}
            type={'new-password'}
            label={'confirm-password'}
            password={confirmPassword}
            setPassword={setConfirmPassword}
            hasError={confirmPasswordErrored}
          />
          <PasswordError errorKey={invalidReasonKey} />
          <button
            type="submit"
            disabled={disableSubmitButton}
            className={classNames('btn btn--md lg:btn--sm btn--primary w-full lg:w-auto', {
              disabled: disableSubmitButton,
            })}
          >
            {t('reset-password')}
          </button>
        </form>
      </div>
    </>
  );
};

export default SetNewPassword;
