import {IUserAgentService} from './i-user-agent-service';

class UserAgentService implements IUserAgentService {
  isTelegramBrowserIOS(): boolean {
    return 'TelegramWebviewProxy' in window;
  }

  isTelegramBrowserAndroid(): boolean {
    return 'TelegramWebview' in window;
  }

  isInstagramBrowser(): boolean {
    return window.navigator.userAgent.toLowerCase().match('instagram') !== null;
  }

  isTikTokBrowser(): boolean {
    return window.navigator.userAgent.toLowerCase().match('musical_ly') !== null;
  }

  isAnyInAppBrowser(): boolean {
    return (
      this.isTelegramBrowserAndroid() ||
      this.isTelegramBrowserIOS() ||
      this.isInstagramBrowser() ||
      this.isTikTokBrowser()
    );
  }
}

export default UserAgentService;
