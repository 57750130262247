import {useI18n} from '../../../hooks/use-i18n';
import {memo, useMemo, lazy, useEffect, useState} from 'react';
import type {CardItem} from './HowDoesItWorkCarouselMobile';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useIsMobile} from '../../../redux/hooks/use-is-mobile';
import {RenderWithSuspense} from '../../../components/RenderWithSuspense';
import {Skeleton} from '../../../ui/Skeleton';
import classNames from 'classnames';
import {Language} from '../../../shared/language';

const HowDoesItWorkDesktop = lazy(() => import(/* webpackPreload: true */ './HowDoesItWorkDesktop'));
const HowDoesItWorkCarouselMobile = lazy(() => import(/* webpackPreload: true */ './HowDoesItWorkCarouselMobile'));

interface Props {
  isCustomer: boolean;
}

const HowDoesItWork = memo((props: Props) => {
  const {t, T, language} = useI18n();
  const {isCustomer} = props;
  const isMobile = useIsMobile();
  const [s1, s2, s3, model2step, model3step, model4step] = useLocalizedLottieAnimations(language);

  const items: CardItem[] = useMemo(() => {
    if (isCustomer) {
      return [
        {
          key: 'create-an-account',
          title: <T keyName={'create-an-account'} params={{br: <br className="hidden lg:block" />}} />,
          animationSrc: s1,
          hide: isMobile,
        },
        {
          key: 'choose-model-you-like',
          title: <T keyName={'choose-model-you-like'} params={{br: <br className="hidden lg:block" />}} />,
          animationSrc: s2,
        },
        {
          key: 'chat-and-buy-photos',
          title: <T keyName={'chat-and-buy-photos'} params={{br: <br className="hidden lg:block" />}} />,
          animationSrc: s3,
        },
      ].filter(({hide}) => !hide);
    } else {
      return [
        {
          key: 'create-an-account',
          title: <T keyName={'create-an-account'} params={{br: <br className="hidden lg:block" />}} />,
          animationSrc: s1,
          hide: isMobile,
        },
        {
          key: 'search-for-customer',
          title: <T keyName={'search-for-customer'} />,
          animationSrc: model2step,
          animationClassName: 'h-[250px]',
        },
        {
          key: 'chat-and-send-photos',
          title: <T keyName={'chat-and-send-photos'} />,
          animationSrc: model3step,
        },
        {
          key: 'withdraw-earnings',
          title: <T keyName={'withdraw-earnings'} />,
          animationSrc: model4step,
        },
      ].filter(({hide}) => !hide);
    }
  }, [isCustomer, s1, s2, s3, model2step, model3step, model4step, isMobile, T]);

  return (
    <div className="container mt-40 lg:mt-50 xl:mt-85">
      <h2 className="mb-28 text-center text-[2.6rem] font-bold sm:text-2xl lg:mb-50 lg:text-left lg:text-3xl xl:mb-85 xl:text-4xl">
        {t('how-does-it-work')}
      </h2>
      {isMobile ? (
        <RenderWithSuspense fallback={<HowDoesItWorkMobileSkeleton />}>
          <HowDoesItWorkCarouselMobile items={items} />
        </RenderWithSuspense>
      ) : (
        <RenderWithSuspense fallback={<HowDoesItWorkDesktopSkeleton items={items} isCustomer={isCustomer} />}>
          <HowDoesItWorkDesktop items={items} isCustomer={isCustomer} />
        </RenderWithSuspense>
      )}
    </div>
  );
});

const HowDoesItWorkMobileSkeleton = () => <Skeleton className={'aspect-square w-full rounded'} />;
const HowDoesItWorkDesktopSkeleton = ({isCustomer, items}: {isCustomer: boolean; items: CardItem[]}) => (
  <div
    className={classNames('grid gap-24', {
      'grid-cols-3 grid-rows-1': isCustomer,
      'grid-cols-2 grid-rows-2': !isCustomer,
    })}
  >
    {items.map((_, idx) => (
      <Skeleton key={idx} className={'h-500 rounded'} />
    ))}
  </div>
);

type LottieAnimations = [any, any, any, any, any, any];

const useLocalizedLottieAnimations = (language: Language): LottieAnimations => {
  const [animations, setAnimations] = useState<LottieAnimations>([null, null, null, null, null, null]);

  useEffect(() => {
    const loadAnimations = async () => {
      const prefix = language === 'en' ? '' : '-ru';
      const [s1, s2, s3, model2step, model3step, model4step] = await Promise.all([
        import(`../../../assets/lottie-animations/s1${prefix}.json`),
        import(`../../../assets/lottie-animations/s2${prefix}.json`),
        import(`../../../assets/lottie-animations/s3${prefix}.json`),
        import(`../../../assets/lottie-animations/model-2-step${prefix}.json`),
        import(`../../../assets/lottie-animations/model-3-step${prefix}.json`),
        import(`../../../assets/lottie-animations/model-4-step${prefix}.json`),
      ]);
      setAnimations([s1.default, s2.default, s3.default, model2step.default, model3step.default, model4step.default]);
    };

    loadAnimations();
  }, [language]);

  return animations;
};

export default HowDoesItWork;
