import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import RelativeTime from 'dayjs/plugin/relativeTime';
import Calendar from 'dayjs/plugin/calendar';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import Duration from 'dayjs/plugin/duration';

dayjs.extend(RelativeTime);
dayjs.extend(Calendar);
dayjs.extend(LocalizedFormat);
dayjs.extend(Duration);

export default dayjs;
