import {api} from './api';
import {di} from '../dependency-injector';

export const checkEmailExists = async (email: string): Promise<boolean> => {
  const lock = await di.inMemoryLockManager.getOrCreateLock('checkEmailExists');
  await lock.acquire();
  try {
    const result = await api.get(`/api/v1/check-email-exists/${email}`);
    return result?.data?.exists ?? false;
  } catch (e) {
    return false;
  } finally {
    lock.release();
  }
};
