import MainHeader from '../../components/MainHeader';
import {Outlet} from 'react-router-dom';
import {ScrollToTop} from '../../components/ScrollToTop';
import {lazy, useEffect} from 'react';
import {RenderWithSuspense} from '../../components/RenderWithSuspense';
import {di} from '../../dependency-injector';

const MainFooter = lazy(() => import('../../components/MainFooter'));

const HomeWrapper = () => {
  useEffect(() => {
    di.broadcastLogin.listen();
    return () => {
      di.broadcastLogin.cleanup();
    };
  }, []);
  return (
    <>
      <ScrollToTop />
      <MainHeader />
      <Outlet />
      <RenderWithSuspense>
        <MainFooter />
      </RenderWithSuspense>
    </>
  );
};

export default HomeWrapper;
