import {Modal} from '../../../../ui/Modal';
import {createRef, useImperativeHandle, useState, useRef} from 'react';
import {useI18n} from '../../../../hooks/use-i18n';
import {CreateAccountModalHandlerRef, LoginModalRef} from '../../types';
import CloseModalButton from '../../../../components/CloseModalButton';
import SignInWithGoogle from '../SignInWithGoogle';
import {useModalState} from '../../../../hooks/use-modal-state';
import SignInWithTwitter from '../SignInWithTwitter';
import OrSeparator from '../OrSeparator';
import CreateAccountForm from './CreateAccountForm/CreateAccountForm';
import AdBlockError from '../AdBlockError';
import CreatePasswordModal from '../CreatePasswordModal/CreatePasswordModal';
import {sleep} from '../../../../util/helpers/sleep';
import OnSuccessLogin, {OnSuccessLoginRef} from '../OnSuccessLogin';
import ExternalBrowserOverlay, {ExternalBrowserOverlayRef} from '../../../../components/ExternalBrowserOverlay';
import {di} from '../../../../dependency-injector';

const createAccountModalRef = createRef<CreateAccountModalHandlerRef>();

const CreateAccountModalHandler: CreateAccountModalHandlerRef = (() => {
  const show: CreateAccountModalHandlerRef['show'] = (): void => {
    if (createAccountModalRef.current?.show) {
      createAccountModalRef.current.show();
    }
  };

  const hide: CreateAccountModalHandlerRef['hide'] = (): void => {
    if (createAccountModalRef.current?.hide) {
      createAccountModalRef.current.hide();
    }
  };

  const twitterLogin: CreateAccountModalHandlerRef['twitterLogin'] = async (twitterToken, twitterVerifier) => {
    // In order to call twitterLogin the modal must be mounted, if it's not open then call and reschedule the task
    while (!createAccountModalRef.current?.isOpen) {
      show();
      await sleep(25); // Wait for the modal to possibly open
    }
    if (createAccountModalRef.current?.twitterLogin) {
      return createAccountModalRef.current?.twitterLogin(twitterToken, twitterVerifier);
    }
  };

  return {
    hide,
    show,
    isOpen: Boolean(createAccountModalRef.current?.isOpen),
    twitterLogin,
  };
})();

const CreateAccountModal = () => {
  const {t} = useI18n();
  const signInWithTwitterRef = useRef<LoginModalRef>(null);
  const successLoginRef = useRef<OnSuccessLoginRef>(null);
  const [showAdBlockError, setShowAdBlockError] = useState(false);
  const [email, setEmail] = useState('');
  const {isOpen, show, hide} = useModalState();
  const {
    isOpen: isOpenCreatePasswordModal,
    show: showCreatePasswordModal,
    hide: hideCreatePasswordModal,
  } = useModalState();
  const externalBrowserOverlayRef = useRef<ExternalBrowserOverlayRef>(null);

  useImperativeHandle(createAccountModalRef, () => ({
    twitterLogin: signInWithTwitterRef.current?.twitterLogin
      ? signInWithTwitterRef.current?.twitterLogin
      : async () => {},
    show: () => {
      if (di.userAgentService.isAnyInAppBrowser()) {
        externalBrowserOverlayRef.current?.show();
      } else {
        show();
      }
    },
    hide,
    isOpen: isOpen || Boolean(externalBrowserOverlayRef.current),
  }));

  const onCreateAccountClick = () => {
    hide();
    showCreatePasswordModal();
  };

  return (
    <>
      <OnSuccessLogin ref={successLoginRef} />
      <ExternalBrowserOverlay ref={externalBrowserOverlayRef} />
      <CreatePasswordModal
        isOpen={isOpenCreatePasswordModal}
        hide={hideCreatePasswordModal}
        email={email}
        onSuccessLogin={(data) => {
          successLoginRef?.current?.onSuccessLogin(data);
          setEmail('');
        }}
      />
      <Modal
        isOpen={isOpen}
        hide={hide}
        backdropClassName={'p-0 lg:p-20'}
        className={'max-h-full w-full rounded px-20 py-24 lg:h-auto lg:w-500 lg:p-54'}
      >
        <CloseModalButton onClick={hide} />
        <div className="mb-14 text-center text-[2.6rem] font-bold sm:text-2xl">{t('create-account')}</div>
        <div className={'flex flex-col items-center justify-center gap-14'}>
          <SignInWithGoogle
            setShowAdBlockError={setShowAdBlockError}
            onSuccessLogin={successLoginRef?.current?.onSuccessLogin}
          />
          <SignInWithTwitter ref={signInWithTwitterRef} onSuccessLogin={successLoginRef?.current?.onSuccessLogin} />
        </div>
        <OrSeparator />
        <CreateAccountForm email={email} setEmail={setEmail} onCreateAccountClick={onCreateAccountClick} />
        <AdBlockError isVisible={showAdBlockError} />
      </Modal>
    </>
  );
};

export default CreateAccountModal;
export {CreateAccountModalHandler};
