import {useI18n} from '../../../hooks/use-i18n';
import {twMerge} from 'tailwind-merge';
import classNames from 'classnames';
import {memo} from 'react';

interface SwitcherProps {
  setIsCustomer: (val: boolean) => void;
  isCustomer: boolean;
  className?: string;
}

export const CustomerModelSwitcher = memo(({setIsCustomer, isCustomer, className}: SwitcherProps) => {
  const {T} = useI18n();
  return (
    <div
      className={twMerge(
        'btn btn--light-primary mx-10 flex flex-row gap-10 rounded-full p-5 text-sm text-white lg:mx-0 lg:mb-20 lg:h-50 lg:w-400 lg:text-[1.5rem]',
        className,
      )}
    >
      <button
        onClick={() => setIsCustomer(true)}
        className={classNames('btn h-full grow px-10 py-14 leading-8 lg:px-0 lg:py-0', {
          'bg-gradient-primary': isCustomer, // enabled styles
          'transparent btn--light-primary-text': !isCustomer, // disabled styles
        })}
      >
        <T
          keyName={'i-want-buy-photos'}
          params={{
            br: <br className={'block lg:hidden'} />,
          }}
        />
      </button>
      <button
        onClick={() => setIsCustomer(false)}
        className={classNames('btn h-full grow px-10 py-14 leading-8 lg:px-0 lg:py-0', {
          'bg-gradient-primary': !isCustomer, // enabled styles
          'transparent btn--light-primary-text': isCustomer, // disabled styles
        })}
      >
        <T
          keyName={'i-want-sell-photos'}
          params={{
            br: <br className={'block lg:hidden'} />,
          }}
        />
      </button>
    </div>
  );
});
