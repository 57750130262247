import {ReactComponent as IconClose} from '../assets/svg/close.svg';

interface Props {
  onClick: () => void;
}

const CloseModalButton = ({onClick}: Props) => {
  return (
    <div className="absolute inset-x-0 -top-1 flex items-center justify-between px-container lg:top-10 lg:px-30 ">
      <button
        className="btn-base -mr-20 ml-auto p-20 text-primary transition-colors hover:text-black"
        aria-label="Close"
        onClick={onClick}
      >
        <IconClose className="fill-current wh-22" />
      </button>
    </div>
  );
};

export default CloseModalButton;
