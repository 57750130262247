import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ISavedWithdrawalMethod} from '../../shared/saved-withdrawal-method';

interface SavedWithdrawalMethodsReducerState {
  methods: ISavedWithdrawalMethod[];
}

const initialSavedWithdrawalMethodsState: SavedWithdrawalMethodsReducerState = {
  methods: [],
};

const savedWithdrawalMethodsSlice = createSlice({
  name: 'savedWithdrawalMethods',
  initialState: initialSavedWithdrawalMethodsState,
  reducers: {
    setWithdrawalMethods: (state, action: PayloadAction<ISavedWithdrawalMethod[]>) => {
      state.methods = action.payload;
    },
  },
});

export const {setWithdrawalMethods} = savedWithdrawalMethodsSlice.actions;
export const savedWithdrawalMethodsReducer = savedWithdrawalMethodsSlice.reducer;
