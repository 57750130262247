import {useInterfaceLanguage} from '../redux/hooks/use-interface-language';
import {Language} from '../shared/language';
import {useEffect, useState} from 'react';
import {useBrowserPrimaryLanguage} from './use-browser-languages';
import {useTranslate, T} from '@tolgee/react';
import {di} from '../dependency-injector';
import {isValidLanguage} from '../shared/is-valid-language';
import {useAppDispatch} from '../redux/hooks/use-app-dispatch';
import {updateInterfaceLanguage as updateInterfaceLanguagePersisted} from '../redux/reducers/global-persisted-reducer';
import {updateInterfaceLanguage as updateInterfaceLanguageSession} from '../redux/reducers/global-reducer';
import {useUserCountry} from './use-user-country';
import {useUserLanguageOrNull} from '../redux/hooks/user-hooks';
import dayjs from '../util/dayjs';

export const useI18n = () => {
  const dispatch = useAppDispatch();

  const userLanguage = useUserLanguageOrNull();
  const interfaceLanguage = useInterfaceLanguage(); // Language that was chosen on landing page
  const {t} = useTranslate();
  const browserPrimaryLanguage = useBrowserPrimaryLanguage();
  const userCountry = useUserCountry();

  const [language, setLanguage] = useState<Language>(() =>
    determineUserLanguage(userLanguage, interfaceLanguage, browserPrimaryLanguage, userCountry),
  );

  const updateInterfaceLanguage = async (newLanguage: Language): Promise<void> => {
    dispatch(updateInterfaceLanguagePersisted(newLanguage));
    dispatch(updateInterfaceLanguageSession(newLanguage));
  };

  useEffect(() => {
    const newLanguage = determineUserLanguage(userLanguage, interfaceLanguage, browserPrimaryLanguage, userCountry);
    setLanguage(newLanguage);
    di.translationService.instance.changeLanguage(newLanguage);
    dayjs.locale(newLanguage);
  }, [interfaceLanguage, browserPrimaryLanguage, userCountry, userLanguage]);

  return {
    t,
    T,
    language,
    updateInterfaceLanguage,
  };
};

const determineUserLanguage = (
  userLanguage: Language | null,
  interfaceLanguage: Language | null,
  browserPrimaryLanguage: string,
  userCountry: string | null,
): Language => {
  if (userLanguage && isValidLanguage(userLanguage)) {
    // user language has the biggest priority
    return userLanguage;
  }
  if (interfaceLanguage && isValidLanguage(interfaceLanguage)) {
    // interface language has the second-biggest priority
    return interfaceLanguage;
  }

  if (browserPrimaryLanguage === 'ru' || userCountry === 'RU') {
    // If browser language is Russian or if user is from Russia return "ru"
    return 'ru';
  }

  // Otherwise return English
  return 'en';
};
