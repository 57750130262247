import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Language} from '../../shared/language';

export type SidebarState = {
  isOpen: boolean;
};

export type GlobalState = {
  isInitialLoading: boolean;
  sidebarState: SidebarState;
  isMobile: boolean;
  isSearching: boolean;
  currentlyPlayingVoice: string | null;
  shouldTakeInputInFocus: boolean;
  userCountry: string | null;
  interfaceLanguage: Language | null;
};

const initialGlobalState: GlobalState = {
  isInitialLoading: true,
  sidebarState: {isOpen: false},
  isMobile: true,
  isSearching: false,
  currentlyPlayingVoice: null,
  shouldTakeInputInFocus: false,
  userCountry: null,
  interfaceLanguage: null,
};

const globalSlice = createSlice({
  name: 'global',
  initialState: initialGlobalState,
  reducers: {
    updateIsInitialLoading(state: GlobalState, action: PayloadAction<boolean>) {
      state.isInitialLoading = action.payload;
    },
    updateIsMobile(state: GlobalState, action: PayloadAction<boolean>) {
      state.isMobile = action.payload;
    },
    updateIsSidebarOpen(state: GlobalState, action: PayloadAction<boolean>) {
      state.sidebarState.isOpen = action.payload;
    },
    updateIsSearching(state: GlobalState, action: PayloadAction<boolean>) {
      state.isSearching = action.payload;
    },
    setCurrentlyPlayingVoice(state: GlobalState, action: PayloadAction<string | null>) {
      state.currentlyPlayingVoice = action.payload;
    },
    updateInterfaceLanguage(state: GlobalState, action: PayloadAction<Language | null>) {
      state.interfaceLanguage = action.payload;
    },
    updateUserCountry(state: GlobalState, action: PayloadAction<string | null>) {
      state.userCountry = action.payload;
    },
    takeTextInputInFocus(state: GlobalState) {
      state.shouldTakeInputInFocus = true;
    },
    resetShouldTakeInputInFocus(state: GlobalState) {
      state.shouldTakeInputInFocus = false;
    },
  },
});

export const {
  updateIsInitialLoading,
  updateIsMobile,
  updateIsSidebarOpen,
  updateIsSearching,
  setCurrentlyPlayingVoice,
  takeTextInputInFocus,
  resetShouldTakeInputInFocus,
  updateInterfaceLanguage,
  updateUserCountry,
} = globalSlice.actions;
export const globalReducer = globalSlice.reducer;
