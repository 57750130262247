import {ReactComponent as IconCoin} from '../../assets/svg/coin.svg';
import {ReactComponent as IconBag} from '../../assets/svg/bag.svg';
import {Link, useNavigate} from 'react-router-dom';
import {Modal, ModalProps} from '../../ui/Modal';
import {api} from '../../http/api';
import {useAppDispatch} from '../../redux/hooks/use-app-dispatch';
import {updateUser} from '../../redux/reducers/user-reducer';
import {useI18n} from '../../hooks/use-i18n';
import {useState} from 'react';
import {ErrorMessage} from '../ErrorMessage';
import {UserType} from '../../shared/user-type';
import {LoginTokenStorage} from '../../storages/login-token-storage';
import {Role} from './Role';
import {CloseButton} from './CloseButton';
import {di} from '../../dependency-injector';

interface Props extends ModalProps {
  cookie: string;
}

type SetRoleResponse = {cookie: string; userId: string};

export const ChooseRoleModal = (props: Props) => {
  const {isOpen, hide, cookie} = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loadingRole, setLoadingRole] = useState<null | UserType>(null);
  const [error, setError] = useState<string>();
  const {t, T} = useI18n();

  async function goToChat(userType: UserType) {
    try {
      setLoadingRole(userType);
      setError(undefined);
      const response = await api.post<SetRoleResponse>(
        'api/v1/set-user-type',
        {
          userType,
        },
        {
          headers: {
            Authorization: cookie,
          },
        },
      );
      // Update user type
      dispatch(
        updateUser({
          userId: response.data.userId,
          type: userType,
        }),
      );
      di.postbackService.sendEvent();
      // Set cookie
      LoginTokenStorage.set(response.data.cookie);
      navigate('/app/chat/search');
    } catch (e) {
      if (e && e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setLoadingRole(null);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      hide={hide}
      backdropClassName={'p-0 lg:p-20'}
      className={
        'min-h-full rounded-none px-20 pb-65 pt-120 text-center sm:p-65 sm:pb-80 lg:min-h-0 lg:w-[63rem] lg:rounded'
      }
    >
      <CloseButton hide={hide} />
      <div className="mb-20 text-[2.6rem] font-bold sm:mb-32 sm:text-2xl">{t('choose-your-role')}</div>
      <div className="grid gap-26 sm:grid-cols-2">
        <Role
          role={'model'}
          Icon={<IconCoin className="wh-14 sm:wh-20" />}
          onClick={() => goToChat('model')}
          buttonText={t('models-earn-money')}
          description={t('i-am-a-model')}
          className={'order-2 sm:order-none'}
          loadingRole={loadingRole}
        />
        <Role
          role={'customer'}
          Icon={<IconBag className="wh-14 sm:wh-20" />}
          onClick={() => goToChat('customer')}
          buttonText={t('customers-chat-with-models')}
          description={t('i-am-a-customer')}
          className={'order-1 sm:order-none'}
          loadingRole={loadingRole}
        />
      </div>
      <div className="prose mb-30 mt-30 text-gray-dark">
        <p>
          <T
            keyName={'by-registering'}
            params={{
              terms: (text) => <Link to={'/terms-of-use.html'}>{text}</Link>,
              privacy: (text) => <Link to={'/privacy-policy.html'}>{text}</Link>,
            }}
          />
        </p>
      </div>
      <ErrorMessage isVisible={Boolean(error)} className={'self-center'}>
        {error}
      </ErrorMessage>
    </Modal>
  );
};

export default ChooseRoleModal;
