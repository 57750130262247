import {REDUX_PERSIST_KEY} from '../constants';

export const reHydrateStore = () => {
  const reduxState = localStorage.getItem(REDUX_PERSIST_KEY);
  if (!reduxState) {
    // Do nothing if there are no persisted state
    return;
  }
  return JSON.parse(reduxState);
};
