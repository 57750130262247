import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IVerificationClient} from '../../shared/verification';

export interface VerificationDraft {
  passportUrl?: VerificationDraftItem;
  selfieUrl?: VerificationDraftItem;
}

type VerificationDraftItem = {
  url: string;
};

type State = {data: Partial<IVerificationClient>; draft: VerificationDraft};
const initialVerificationState: State = {data: {}, draft: {}};

const verificationSlice = createSlice({
  name: 'verification',
  initialState: initialVerificationState,
  reducers: {
    updateVerificationInfo(state: State, action: PayloadAction<IVerificationClient>) {
      state.data = action.payload;
    },
    updateVerificationDraft(state: State, action: PayloadAction<VerificationDraft>) {
      state.draft = {...state.draft, ...action.payload};
    },
    clearVerificationDraft(state: State, action: PayloadAction<keyof VerificationDraft>) {
      delete state.draft[action.payload];
    },
  },
});

export const {updateVerificationInfo, updateVerificationDraft, clearVerificationDraft} = verificationSlice.actions;
export const verificationReducer = verificationSlice.reducer;
export {initialVerificationState};
