import axios from 'axios';
import {getConnectUrl} from '../util/get-connect-url';
import {LoginTokenStorage} from '../storages/login-token-storage';
import {sleep} from '../util/helpers/sleep';

// Custom configuration for retry logic
const MAX_RETRIES = 3;
const RETRY_DELAY = 100; // in milliseconds

export const api = axios.create({
  baseURL: getConnectUrl(),
  timeout: 20000,
});

api.interceptors.request.use((config) => {
  if (config.headers && !config.headers.Authorization) {
    config.headers.Authorization = LoginTokenStorage.get() ?? '';
  }
  return config;
});

// Retry interceptor
api.interceptors.response.use(undefined, (err) => {
  // Initialize __retryCount if it's undefined
  if (!err.config.__retryCount) {
    err.config.__retryCount = 0;
  }
  const isTimeoutErr = err.config && err.code === 'ECONNABORTED';
  const canRetry = err.config.__retryCount < MAX_RETRIES;
  // Perform retry if needed
  if (isTimeoutErr && canRetry) {
    err.config.__retryCount += 1;
    const jitterDelay = RETRY_DELAY * err.config.__retryCount + Math.random() * RETRY_DELAY;
    // Retry request after sleep
    return sleep(jitterDelay).then(() => {
      return api(err.config);
    });
  }
  return Promise.reject(err);
});
