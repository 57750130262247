import {Fragment, PropsWithChildren} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {twMerge} from 'tailwind-merge';

export interface ModalProps {
  isOpen: boolean;
  hide: () => void;
  className?: string;
  backdropClassName?: string;
  modalClassName?: string;
}

export const Modal = (props: PropsWithChildren<ModalProps>) => {
  const {isOpen, hide, children, className, backdropClassName, modalClassName} = props;
  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enter="transition duration-100 ease-out"
      enterFrom="opacity-0"
      leave="transition duration-75 ease-out"
      leaveTo="opacity-0"
    >
      <Dialog open={isOpen} onClose={hide} className={twMerge('modal', modalClassName)}>
        <Dialog.Overlay className={twMerge('modal-backdrop', backdropClassName)}>
          <div
            className={twMerge(
              'modal-content modal-content--primary flex flex-col self-end overflow-y-auto lg:self-auto',
              className,
            )}
          >
            {children}
          </div>
        </Dialog.Overlay>
      </Dialog>
    </Transition>
  );
};
