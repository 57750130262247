import {Tolgee, DevTools, TolgeeInstance} from '@tolgee/react';
import {FormatIcu} from '@tolgee/format-icu';
import {ITranslationService} from './i-translation-service';

class TranslationsService implements ITranslationService {
  private readonly tolgee: TolgeeInstance;

  constructor() {
    this.tolgee = Tolgee()
      .use(DevTools())
      .use(FormatIcu())
      .init({
        language: 'en',
        // for development
        apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
        apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
        // for production
        staticData: {
          en: () => import('./i18n/en.json'),
          ru: () => import('./i18n/ru.json'),
        },
      });
  }

  get instance() {
    return this.tolgee;
  }
}

export default TranslationsService;
