import {ClipLoader} from 'react-spinners';

interface Props {
  isLoading: boolean;
  size?: number;
  color?: string;
  className?: string;
}

export const LoadingIndicator = (props: Props) => {
  const {isLoading, size, color, className} = props;

  if (!isLoading) {
    return null;
  }

  return <ClipLoader size={size} color={color} className={className} />;
};
