import {UnknownAction, Middleware} from '@reduxjs/toolkit';
import {REDUX_PERSIST_KEY} from '../constants';

const reducersToPersist = [
  'verification',
  'user',
  'globalPersisted',
  'savedPaymentMethods',
  'savedWithdrawalMethods',
  'validationProfileErrors',
];

export const persistMiddleware: Middleware = ({getState}) => {
  return (next) => (action) => {
    const result = next(action);
    // action.type is basically [reducerName]/[actionName]
    const reducer = (action as UnknownAction).type.split('/')[0];
    if (reducersToPersist.includes(reducer)) {
      const state = getState();

      // Pick reducers we want to persist
      const stateToStore = {
        user: state.user,
        verification: state.verification,
        globalPersisted: state.globalPersisted,
        savedPaymentMethods: state.savedPaymentMethods,
        savedWithdrawalMethods: state.savedWithdrawalMethods,
      };

      localStorage.setItem(REDUX_PERSIST_KEY, JSON.stringify(stateToStore));
    }
    return result;
  };
};
