import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IUserClient, IUserSettings} from '../../shared/user';
import {UserType} from '../../shared/user-type';

type State = {data: Partial<IUserClient>; amountCentsOnHold: number};
const initialUserState: State = {
  data: {},
  amountCentsOnHold: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    updateUser(state: State, action: PayloadAction<Partial<IUserClient>>) {
      state.data = action.payload;
    },
    updateProfileSettings(state: State, action: PayloadAction<IUserSettings>) {
      state.data.settings = action.payload;
    },
    updateUserType(state: State, action: PayloadAction<UserType>) {
      state.data.type = action.payload;
    },
    updateAmountCentsOnHold(state: State, action: PayloadAction<number>) {
      state.amountCentsOnHold = action.payload;
    },
  },
});

export const {updateProfileSettings, updateUser, updateUserType, updateAmountCentsOnHold} = userSlice.actions;
export const userReducer = userSlice.reducer;
export {initialUserState};
