import {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import React, {Children} from 'react';

const ActiveLink = ({
  children,
  activeClassName,
  ...props
}: {
  children?: any;
  activeClassName?: string;
  as?: string;
  href: string;
}) => {
  const child = Children.only(children);
  const childClassName = child.props.className || '';
  const [className, setClassName] = useState(childClassName);
  const location = useLocation();

  useEffect(() => {
    // Dynamic route will be matched via props.as
    // Static route will be matched via props.href
    const linkPathname = props.as || props.href;

    // Using URL().pathname to get rid of query and hash
    const activePathname = location.pathname;

    const newClassName =
      linkPathname === activePathname ? `${childClassName} ${activeClassName}`.trim() : childClassName;

    if (newClassName !== className) {
      setClassName(newClassName);
    }
  }, [
    // asPath,
    // isReady,
    props.as,
    props.href,
    childClassName,
    activeClassName,
    setClassName,
    className,
    location,
  ]);

  return (
    <Link to={props.href} {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </Link>
  );
};

export default ActiveLink;
