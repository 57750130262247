import ActiveLink from './ActiveLink';
import {ReactComponent as IconAnonFun} from '../assets/svg/anonfun-logo-with-name.svg';
import {useI18n} from '../hooks/use-i18n';
import {di} from '../dependency-injector';
import {CreateAccountModal, LoginModal, LoginModalHandler} from '../modules/login';
import {Spacer} from './Spacer';
import {LanguagePickerLanding} from '../modules/language-picker';
import ExternalBrowserBanner from './ExternalBrowserBanner';

const MainHeader = () => {
  const {t} = useI18n();
  const onJoinClick = () => {
    di.trafficAnalyticsService.trackClickRegister();
    LoginModalHandler.show();
  };
  return (
    <>
      <LoginModal />
      <CreateAccountModal />
      <ExternalBrowserBanner />
      <div className="container mb-10 mt-20 flex w-full items-center lg:mb-20 lg:mt-25">
        <ActiveLink href="/">
          <div className={'flex flex-row gap-5'}>
            <IconAnonFun />
          </div>
        </ActiveLink>
        <Spacer />
        <LanguagePickerLanding />
        <button className="btn btn--sm btn--primary min-w-110" onClick={onJoinClick}>
          {t('log-in')}
        </button>
      </div>
    </>
  );
};
export default MainHeader;
