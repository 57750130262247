import {TrafficAnalyticsEvent} from './traffic-analytics-event';

export interface ITrafficAnalytics {
  userId: string;
  clickId: string;
  events: Record<TrafficAnalyticsEvent, number>;
  utm: Record<string, string>;
  rowNumber?: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITrafficAnalyticsAggregatedResult {
  date: string;
  source: string;
  campaign_id: string;
  geo: string;
  creative_id: string;
  cost: number;
  visit: number;
  'click-register': number;
  register: number;
  'choose-role': number;
  'click-start-chat': number;
  'donate-start-chat': number;
  'open-finance': number;
  'open-add-funds-modal': number;
  'navigate-to-deposit-page': number;
  'success-deposit': number;
}

export const MODEL_LINK_SOURCE = 'model-link';
