import {AxiosInstance} from 'axios';
import {
  TrafficAnalyticsEvent,
  TrafficAnalyticsTrackEventData,
  TrafficAnalyticsTrackUserEventData,
} from '../../shared/traffic-analytics-event';
import {ILockManager} from '../../util/lock/i-lock';
import {generateUuid} from '../../util/helpers/generate-uuid';
import {UtmParams} from '../../shared/utm-params';
import ClickIdStorage from '../../storages/click-id-storage';
import {MODEL_LINK_SOURCE} from '../../shared/traffic-analytics';

class TrafficAnalyticsService {
  private readonly httpClient: AxiosInstance;
  private readonly lockManager: ILockManager;

  constructor(httpClient: AxiosInstance, inMemoryLockService: ILockManager) {
    this.httpClient = httpClient;
    this.lockManager = inMemoryLockService;
  }

  async trackVisit(): Promise<void> {
    const utmParams = this.getUTMParams();
    if (!utmParams) {
      // If no utm params were parsed then don't track
      return;
    }

    const {clickId, utm} = utmParams;
    await this.sendEventToServer('visit', clickId, utm);
    ClickIdStorage.set(clickId);
  }

  async trackModelPageVisit(modelId: string): Promise<void> {
    // Append model page specific utm params if they aren't specified
    const utmParams = this.getUTMParams();
    if (!utmParams?.utm?.source) {
      this.appendQueryParam('source', MODEL_LINK_SOURCE);
    }
    if (!utmParams?.utm?.campaign_id) {
      this.appendQueryParam('campaign_id', modelId);
    }
    if (!utmParams?.utm?.format && document.referrer) {
      // Append referrer to utm if exists
      this.appendQueryParam('format', document.referrer);
    }

    return this.trackVisit();
  }

  async trackClickRegister(): Promise<void> {
    const utmParams = this.getUTMParams();
    const clickId = utmParams?.clickId ?? ClickIdStorage.get();
    if (!clickId) {
      // If no utm params and click id then don't track
      return;
    }

    await this.sendEventToServer('click-register', clickId);
  }

  async trackClickStartChat(): Promise<void> {
    await this.sendUserEventToServer('click-start-chat');
  }

  async trackOpenFinance(): Promise<void> {
    await this.sendUserEventToServer('open-finance');
  }

  async trackOpenAddFundsModal(): Promise<void> {
    await this.sendUserEventToServer('open-add-funds-modal');
  }

  async trackNavigateToDepositPage(): Promise<void> {
    await this.sendUserEventToServer('navigate-to-deposit-page');
  }

  async trackUploadPassportPhoto(): Promise<void> {
    await this.sendUserEventToServer('upload-passport-photo');
  }

  async trackUploadSelfiePhoto(): Promise<void> {
    await this.sendUserEventToServer('upload-selfie-photo');
  }

  async trackScrollModels(): Promise<void> {
    await this.sendUserEventToServer('scroll-models');
  }

  private async sendEventToServer(
    eventName: TrafficAnalyticsEvent,
    clickId: string,
    utm?: Record<string, string>,
  ): Promise<void> {
    const lock = this.lockManager.getOrCreateLock('traffic-analytics-service');
    await lock.acquire();
    try {
      await this.httpClient.post<unknown, unknown, TrafficAnalyticsTrackEventData>(
        '/traffic-analytics/track-event',
        {eventName, clickId, utm},
        {withCredentials: true},
      );
    } finally {
      lock.release();
    }
  }

  private async sendUserEventToServer(eventName: TrafficAnalyticsEvent): Promise<void> {
    await this.httpClient.post<unknown, unknown, TrafficAnalyticsTrackUserEventData>(
      '/traffic-analytics/track-user-event',
      {
        eventName,
      },
    );
  }

  /* 
                    Checks if user has clickId stored in cookie
                  */
  private hasClickId(): boolean {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf('clickId=') === 0) {
        return true;
      }
    }
    return false;
  }

  getUTMParams(): UtmParams | null {
    // If there's no search param then quit right away
    if (!window.location.search) {
      return null;
    }

    const params = new URLSearchParams(window.location.search);
    const utm: Record<string, string> = {};

    // Iterate through params and build an object
    for (const [key, value] of params) {
      utm[key] = value;
    }

    // get or create clickId
    const clickId = params.get('clickId') ?? generateUuid();
    // add to the search params
    this.appendQueryParam('clickId', clickId);
    // remove from utms
    delete utm['clickId'];

    return {utm, clickId};
  }

  private appendQueryParam(key: string, value: string) {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    searchParams.set(key, value);
    currentUrl.search = searchParams.toString();
    window.history.pushState({path: currentUrl.href}, '', currentUrl.href);
  }
}

export default TrafficAnalyticsService;
