import {IStorage} from './i-storage';

class ResetPasswordSessionStorage implements IStorage {
  private readonly _key = 'reset-password-email';

  get() {
    return localStorage.getItem(this._key);
  }

  set(value: string) {
    return localStorage.setItem(this._key, value);
  }

  delete() {
    return localStorage.removeItem(this._key);
  }
}

const resetPasswordSessionStorage = new ResetPasswordSessionStorage();
export default resetPasswordSessionStorage;
