import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IAccountDeletionClient} from '../../shared/account-deletion';
import {Language} from '../../shared/language';

export enum RejectedVerificationScreenState {
  SHOW = 'show',
  SEEN = 'seen',
}

export type GlobalPersistedState = {
  rejectedVerificationState: RejectedVerificationScreenState | null;
  deletionAccountState: IAccountDeletionClient | null;
  interfaceLanguage: Language | null;
  userCountry: string | null;
};

const initialGlobalState: GlobalPersistedState = {
  rejectedVerificationState: null,
  deletionAccountState: null,
  interfaceLanguage: null,
  userCountry: null,
};

const globalPersistedSlice = createSlice({
  name: 'globalPersisted',
  initialState: initialGlobalState,
  reducers: {
    updateRejectedVerificationState(
      state: GlobalPersistedState,
      action: PayloadAction<RejectedVerificationScreenState | null>,
    ) {
      state.rejectedVerificationState = action.payload;
    },
    updateDeletionAccountState(state: GlobalPersistedState, action: PayloadAction<IAccountDeletionClient | null>) {
      state.deletionAccountState = action.payload;
    },
    updateInterfaceLanguage(state: GlobalPersistedState, action: PayloadAction<Language>) {
      state.interfaceLanguage = action.payload;
    },
    updateUserCountry(state: GlobalPersistedState, action: PayloadAction<string | null>) {
      state.userCountry = action.payload;
    },
  },
});

export const {updateRejectedVerificationState, updateDeletionAccountState, updateInterfaceLanguage, updateUserCountry} =
  globalPersistedSlice.actions;
export const globalPersistedReducer = globalPersistedSlice.reducer;
