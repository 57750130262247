import {useI18n} from '../../../hooks/use-i18n';
import {WrongEmailOrPasswordEasy} from '../../../shared/shared-error';

interface Props {
  errorKey: string | null;
  onClick?: () => void;
}

export const PasswordError = (props: Props) => {
  const {errorKey, onClick} = props;
  const {t, T} = useI18n();

  if (!errorKey) {
    return null;
  }

  return (
    <div
      data-testid="password-error"
      className={'mb-14 max-w-full animate-appearance text-nano font-semibold text-red lg:text-[1.5rem] lg:text-tiny'}
    >
      {errorKey === WrongEmailOrPasswordEasy.translation ? (
        <T
          keyName={errorKey}
          params={{
            button: (text) => (
              <button className={'btn-base link text-red'} onClick={onClick}>
                {text}
              </button>
            ),
          }}
        />
      ) : (
        t(errorKey)
      )}
    </div>
  );
};
