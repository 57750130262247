import {ILock} from '../i-lock';

class InMemoryLock implements ILock {
  private isLocked: boolean = false;
  private waitingResolvers: (() => void)[] = [];

  public async acquire(): Promise<void> {
    // If lock is free then acquire it
    if (!this.isLocked) {
      this.isLocked = true;
      return;
    }

    // If lock is not free then put the waiter to the queue
    return new Promise((resolve) => {
      this.waitingResolvers.push(resolve);
    });
  }

  public release(): void {
    if (!this.isLocked) {
      throw new Error('Lock is not acquired');
    }

    // If no one is waiting then release the lock
    if (this.waitingResolvers.length === 0) {
      this.isLocked = false;
      return;
    }

    // Take the first waiter from the queue and allow them to run the code
    const resolve = this.waitingResolvers.shift();
    resolve?.();
  }

  public isAcquired(): boolean {
    return this.isLocked;
  }
}

export default InMemoryLock;
