import {useI18n} from '../../../hooks/use-i18n';

const AdBlockError = ({isVisible}: {isVisible: boolean}) => {
  const {T} = useI18n();
  if (!isVisible) {
    return null;
  }
  return (
    <div className="mt-24 text-center font-bold text-red">
      <T keyName={'disable-adblock'} params={{br: <br className={'lg:hidden'} />}} />
    </div>
  );
};

export default AdBlockError;
