import React, {useState, useImperativeHandle, forwardRef} from 'react';
import ExternalBrowserLoginBubble from '../modules/login/components/LoginModal/ExternalBrowserLoginBubble';
import {EXTERNAL_BROWSER_BANNER_HEIGHT_PX} from '../styles/LayoutConstants';

export interface ExternalBrowserOverlayRef {
  show: () => void;
  hide: () => void;
}

const ExternalBrowserOverlay = forwardRef<ExternalBrowserOverlayRef>((_, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => setIsVisible(true),
    hide: () => setIsVisible(false),
  }));

  if (!isVisible) return null;

  const bubbleTopPosition = EXTERNAL_BROWSER_BANNER_HEIGHT_PX + 8; // 8px offset

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-20" onClick={() => setIsVisible(false)} />
      <div
        className="fixed right-20 z-30"
        // Style applied directly, not though Tailwind,
        // because TW doesn't work with atrbitrary values from a variable
        style={{top: `${bubbleTopPosition}px`}}
      >
        <ExternalBrowserLoginBubble />
      </div>
    </>
  );
});

export default ExternalBrowserOverlay;
