import {NavigateFunction, useNavigate} from 'react-router-dom';
import {createRef, forwardRef, useImperativeHandle} from 'react';

export interface NavigationRef {
  navigate: NavigateFunction;
}

const navigateRef = createRef<NavigationRef>();

const NavigationView = forwardRef<NavigationRef, unknown>((_, ref) => {
  const navigate = useNavigate();
  useImperativeHandle(navigateRef, () => ({
    navigate,
  }));
  return <></>;
});

export {NavigationView, navigateRef};
