import {isDev} from './util/helpers/is-dev';
import {Metric} from 'web-vitals/src/types/base';

const reportWebVitals = (onReport?: (metric: Metric) => void) => {
  if (!isDev()) {
    return;
  }
  if (onReport && onReport instanceof Function) {
    import('web-vitals').then(({onCLS, onFID, onFCP, onLCP, onTTFB}) => {
      onCLS(onReport);
      onFID(onReport);
      onFCP(onReport);
      onLCP(onReport);
      onTTFB(onReport);
    });
  }
};

export default reportWebVitals;
