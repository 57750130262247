import React, {PropsWithChildren} from 'react';
import {LoadingIndicator} from './LoadingIndicator';
import {twMerge} from 'tailwind-merge';
import classNames from 'classnames';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isFetching: boolean;
  childrenClassName?: string;
  loadingIndicatorColor?: string;
  loadingIndicatorSize?: number;
}

export const ButtonWithLoader = (props: PropsWithChildren<Props>) => {
  const {
    children,
    isFetching,
    childrenClassName,
    disabled,
    loadingIndicatorColor = '#8423FF',
    loadingIndicatorSize = 20,
    className,
    ...rest
  } = props;
  return (
    <button
      {...rest}
      disabled={isFetching || disabled}
      className={twMerge(classNames('relative group', {'opacity-80': isFetching}), className)}
      data-testid={'btn'}
    >
      {isFetching && (
        <div data-testid={'loading-indicator'} className={'absolute self-center'}>
          <LoadingIndicator isLoading={isFetching} size={loadingIndicatorSize} color={loadingIndicatorColor} />
        </div>
      )}
      <div data-testid={'children-div'} className={twMerge(childrenClassName, classNames({'opacity-0': isFetching}))}>
        {children}
      </div>
    </button>
  );
};
