import {useI18n} from '../../../hooks/use-i18n';
import {Listbox} from '@headlessui/react';
import {languagesList} from '../../../shared/language';
import {ReactComponent as ArrowDownIcon} from '../../../assets/svg/arrow-down.svg';
import classNames from 'classnames';

const LanguagePickerLanding = () => {
  const {language, updateInterfaceLanguage} = useI18n();
  return (
    <Listbox value={language} onChange={updateInterfaceLanguage}>
      <div className={'relative w-fit flex flex-col justify-center items-center'}>
        <Listbox.Button className="btn flex px-30 flex-row gap-5 text-black font-semibold text-base items-center justify-center">
          {languagesList[language]}
          <ArrowDownIcon />
        </Listbox.Button>
        <Listbox.Options className="top-30 absolute w-fit overflow-auto rounded-md bg-white text-base flex flex-col z-1">
          {Object.entries(languagesList).map(([key, val], idx, arr) => {
            const isLast = idx === arr.length - 1;
            return (
              <div key={key}>
                <Listbox.Option
                  className={({selected, active}) =>
                    classNames('btn py-12 px-28 cursor-pointer', {
                      'text-gradient-primary': active || selected,
                    })
                  }
                  value={key}
                >
                  {val}
                </Listbox.Option>
                {!isLast && <div className={'w-full h-1 bg-black opacity-25'} />}
              </div>
            );
          })}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default LanguagePickerLanding;
