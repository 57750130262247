import classNames from 'classnames';
import {useI18n} from '../../../hooks/use-i18n';
import {Listbox} from '@headlessui/react';
import {Language, languagesList} from '../../../shared/language';
import {useFetchingState} from '../../../hooks/use-is-fetching';
import {emitUpdateLanguage} from '../../../socket/outgoing-events/emit-update-language';
import {ReactComponent as ArrowDownIcon} from '../../../assets/svg/arrow-down.svg';
import {ClipLoader} from 'react-spinners';

const LanguagePickerApp = () => {
  const {t, language} = useI18n();
  const [wrappedEmitUpdateLanguage, isFetching] = useFetchingState(emitUpdateLanguage);

  const onChange = (newLanguage: Language) => {
    wrappedEmitUpdateLanguage({language: newLanguage});
  };

  return (
    <div className="relative flex flex-grow flex-col justify-start lg:max-w-540 lg:items-start">
      <div className="mb-8 font-bold lg:mb-10">{t('language')}</div>
      <div className="mb-20 text-gray-dark lg:mb-28">{t('language-page-desc')}</div>
      <Listbox value={language} onChange={onChange} disabled={isFetching}>
        <div
          className={classNames('relative w-full flex flex-col justify-center items-center', {
            'opacity-90': isFetching,
          })}
        >
          <Listbox.Button className="btn flex px-24 py-20 flex-row text-black font-semibold text-base items-center justify-between bg-white w-full rounded-sm">
            <div className={'flex flex-row gap-10 justify-center items-center'}>
              {isFetching && <ClipLoader size={15} color={'black'} />}
              {languagesList[language]}
            </div>
            <ArrowDownIcon />
          </Listbox.Button>
          <Listbox.Options className="top-75 absolute w-full overflow-auto rounded-sm bg-white text-base flex flex-col">
            {Object.entries(languagesList).map(([key, val], idx, arr) => {
              const isLast = idx === arr.length - 1;
              return (
                <div key={key}>
                  <Listbox.Option
                    className={({selected, active}) =>
                      classNames('btn px-24 py-20 cursor-pointer w-full flex justify-start', {
                        'text-gradient-primary': active || selected,
                      })
                    }
                    value={key}
                  >
                    {val}
                  </Listbox.Option>
                  {!isLast && <div className={'w-full h-1 bg-black opacity-25'} />}
                </div>
              );
            })}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
};

export default LanguagePickerApp;
