import {Link} from 'react-router-dom';
import {useI18n} from '../../../../hooks/use-i18n';

const CreatePasswordBottomDescription = () => {
  const {t, T} = useI18n();
  return (
    <div className={'mt-16 flex flex-col gap-16 px-40 text-center text-base text-black/60'}>
      <p>{t('confirm-at-least-18-years-old')}</p>
      <p>
        <T
          keyName={'already-have-account-log-in'}
          params={{
            button: (text) => (
              <Link to={'/login'} className={'link link--primary-gradient'}>
                {text}
              </Link>
            ),
          }}
        />
      </p>
    </div>
  );
};

export default CreatePasswordBottomDescription;
