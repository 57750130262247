import {useLocation} from 'react-router-dom';
import {UserType} from '../../../shared/user-type';

export const usePresetRole = (): UserType | undefined => {
  const {pathname} = useLocation();
  const isOnModelPage = pathname.startsWith('/model/');
  if (!isOnModelPage) {
    return undefined;
  }
  return 'customer';
};
