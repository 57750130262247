import {useI18n} from '../hooks/use-i18n';
import {ReactComponent as IconArrowUp} from '../assets/svg/arrow-up-long.svg';
import {di} from '../dependency-injector';
import {useRef} from 'react';
import ExternalBrowserOverlay, {ExternalBrowserOverlayRef} from './ExternalBrowserOverlay';
import {EXTERNAL_BROWSER_BANNER_HEIGHT_PX} from '../styles/LayoutConstants';

const ExternalBrowserBanner = () => {
  const {T} = useI18n();
  const overlayRef = useRef<ExternalBrowserOverlayRef>(null);

  if (!di.userAgentService.isAnyInAppBrowser()) {
    // Show banner only if user uses in app browser
    return null;
  }

  const symbol = di.userAgentService.isTelegramBrowserAndroid() ? '⋮' : '•••';

  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 z-30 text-white flex flex-row gap-10 justify-end items-center px-15 bg-gradient-primary"
        // Style applied directly, not though Tailwind,
        // because TW doesn't work with atrbitrary values from a variable
        style={{height: `${EXTERNAL_BROWSER_BANNER_HEIGHT_PX}px`}}
      >
        <span>
          <T
            keyName={'external-browser-hint-custom-symbol'}
            params={{b: (text) => <b>{text}</b>, symbol: () => <>{symbol}</>}}
          />
        </span>
        <IconArrowUp className={'stroke-white'} onClick={() => overlayRef.current?.show()} />
      </div>

      {/* 
      Empty div to push the rest of the website down, since banner is fixed.
      Style applied directly, not though Tailwind,
      because TW doesn't work with atrbitrary values from a variable
      */}
      <div style={{height: `${EXTERNAL_BROWSER_BANNER_HEIGHT_PX}px`}} />

      <ExternalBrowserOverlay ref={overlayRef} />
    </>
  );
};

export default ExternalBrowserBanner;
