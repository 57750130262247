import {IBroadcast} from './i-broadcast';

class BroadcastLogin implements IBroadcast {
  private LOGIN_EVENT = 'anonfun_login';
  private storageListener: (event: StorageEvent) => void;

  constructor() {
    this.storageListener = (event) => {
      if (event.key === this.LOGIN_EVENT) {
        // If auth state changed in another tab, refresh the current tab
        window.location.reload();
      }
    };
  }

  listen() {
    window.addEventListener('storage', this.storageListener);
  }

  cleanup() {
    window.removeEventListener('storage', this.storageListener);
  }

  broadcast() {
    localStorage.setItem(this.LOGIN_EVENT, Date.now().toString());
  }
}

export default BroadcastLogin;
