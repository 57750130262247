import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TypingStatus} from '../../shared/typing-status';

type TypingStatusesStateValue = {
  status: TypingStatus;
  validUntil: number;
};

type TypingStatusesState = {
  [dialogId: string]: TypingStatusesStateValue;
};

const initialState: TypingStatusesState = {};

const TYPING_STATUS_DURATION_MS = 3000;

const typingStatusesSlice = createSlice({
  name: 'typingStatuses',
  initialState,
  reducers: {
    updateTypingStatus: (state, action: PayloadAction<{dialogId: string; status: TypingStatus}>) => {
      const {dialogId, status} = action.payload;
      state[dialogId] = {
        status,
        validUntil: Date.now() + TYPING_STATUS_DURATION_MS,
      };
    },
  },
});

export const {updateTypingStatus} = typingStatusesSlice.actions;
export const typingStatusesReducer = typingStatusesSlice.reducer;
