import {IBroadcast} from './i-broadcast';

class BroadcastLogout implements IBroadcast {
  private LOGOUT_EVENT = 'anonfun_logout';
  private storageListener: (event: StorageEvent) => void;

  constructor() {
    this.storageListener = (event: StorageEvent) => {
      if (event.key === this.LOGOUT_EVENT) {
        window.location.href = '/';
      }
    };
  }

  listen() {
    window.addEventListener('storage', this.storageListener);
  }

  cleanup() {
    window.removeEventListener('storage', this.storageListener);
  }

  broadcast() {
    localStorage.setItem(this.LOGOUT_EVENT, Date.now().toString());
    localStorage.removeItem(this.LOGOUT_EVENT);
  }
}

export default BroadcastLogout;
