import axios from 'axios';

export const getUserCountry = async (): Promise<string | null> => {
  try {
    const response = await axios('https://geoapi.stivisto.com/analyze-ip?ip=mine');
    return response.data?.result?.country_iso_code ?? null;
  } catch (e) {
    return null;
  }
};
