import {useI18n} from '../../../../../hooks/use-i18n';
import {CreateAccountModalHandler} from '../CreateAccountModal';
import {LoginModalHandler} from '../../LoginModal/LoginModal';

const CreateAccountBottomDescription = () => {
  const {t, T} = useI18n();
  return (
    <div className={'mt-14 flex flex-col gap-14 px-40 text-center text-base text-black/60'}>
      <p>{t('confirm-at-least-18-years-old')}</p>
      <p>
        <T
          keyName={'already-have-account-log-in'}
          params={{
            button: (text) => (
              <button
                type={'button'}
                className={'btn-base link link--primary-gradient'}
                onClick={() => {
                  LoginModalHandler.show();
                  CreateAccountModalHandler.hide();
                }}
              >
                {text}
              </button>
            ),
          }}
        />
      </p>
    </div>
  );
};

export default CreateAccountBottomDescription;
