import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ISavedPaymentMethod} from '../../shared/saved-payment-method';

interface SavedPaymentMethodsReducerState {
  methods: ISavedPaymentMethod[];
}

const initialSavedPaymentMethodsState: SavedPaymentMethodsReducerState = {
  methods: [],
};

const savedPaymentMethodsSlice = createSlice({
  name: 'savedPaymentMethods',
  initialState: initialSavedPaymentMethodsState,
  reducers: {
    setPaymentMethods: (state, action: PayloadAction<ISavedPaymentMethod[]>) => {
      state.methods = action.payload;
    },
  },
});

export const {setPaymentMethods} = savedPaymentMethodsSlice.actions;
export const savedPaymentMethodsReducer = savedPaymentMethodsSlice.reducer;
export {initialSavedPaymentMethodsState};
