import {IStorage} from './i-storage';

class ClickIdStorage implements IStorage {
  private readonly _clickIdKey: string = 'anonfun-click-id';

  get() {
    return localStorage.getItem(this._clickIdKey);
  }

  set(value: string) {
    return localStorage.setItem(this._clickIdKey, value);
  }

  delete() {
    return localStorage.removeItem(this._clickIdKey);
  }
}

const clickIdStorage = new ClickIdStorage();
export default clickIdStorage;
