import {useEffect, useState} from 'react';

export const useBrowserLanguages = (): readonly string[] => {
  const [browserLanguages, setBrowserLanguages] = useState(navigator.languages || [navigator.language]);

  useEffect(() => {
    const updateBrowserLanguages = () => setBrowserLanguages(navigator.languages || [navigator.language]);
    window.addEventListener('languagechange', updateBrowserLanguages);
    return () => {
      window.removeEventListener('languagechange', updateBrowserLanguages);
    };
  }, []);

  return browserLanguages;
};

export const useBrowserPrimaryLanguage = (): string => {
  const browserLanguages = useBrowserLanguages();
  return browserLanguages?.[0] ?? 'en';
};
