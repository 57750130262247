import {ReactComponent as IconGoggle} from '../../../assets/svg/google.svg';
import {useI18n} from '../../../hooks/use-i18n';
import {useGoogleLogin} from '@react-oauth/google';
import {api} from '../../../http/api';
import ClickIdStorage from '../../../storages/click-id-storage';
import {di} from '../../../dependency-injector';
import {LoginButtonProps} from '../types';
import {AxiosError} from 'axios';
import {usePresetRole} from '../lib/usePresetRole';
import {useUserCountry} from '../../../hooks/use-user-country';

interface Props extends LoginButtonProps {
  setShowAdBlockError: (val: boolean) => void;
}

const SignInWithGoogle = (props: Props) => {
  const {onSuccessLogin, setShowAdBlockError} = props;
  const {t, language} = useI18n();
  const userCountry = useUserCountry();
  const utmParams = di.trafficAnalyticsService.getUTMParams();
  const presetRole = usePresetRole();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const response = await api.post('api/v1/login', {
          method: 'google',
          data: {
            language,
            userCountry,
            accessToken: tokenResponse.access_token,
            clickId: utmParams?.clickId ?? ClickIdStorage.get(),
            userType: presetRole,
          },
        });
        if (!response.data.success) {
          alert(response.data.error);
          return;
        }
        onSuccessLogin?.(response.data.data);
      } catch (e) {
        if (e instanceof AxiosError) {
          alert(e?.response?.data?.error);
          return;
        }
        alert(e);
      }
    },
  });
  const onGoogleLoginClick = () => {
    login();
    if (!window.canRunAdsAnonFun) {
      setShowAdBlockError(true);
    }
  };
  return (
    <button className="btn btn--sm btn--white h-52 w-full gap-16 p-16" onClick={onGoogleLoginClick}>
      <IconGoggle />
      {t('sign-in-with-google')}
    </button>
  );
};

export default SignInWithGoogle;
