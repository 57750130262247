import {useI18n} from '../../../hooks/use-i18n';

const OrSeparator = () => {
  const {t} = useI18n();
  return (
    <div className={'flex flex-row items-center justify-center gap-16 py-24'}>
      <GrayLine />
      <span className={'text-base text-black/60'}>{t('or')}</span>
      <GrayLine />
    </div>
  );
};

const GrayLine = () => <div className={'h-1 flex-shrink flex-grow bg-[#E0E0E0]'} />;

export default OrSeparator;
